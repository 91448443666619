.calendar {
  .activeLine {
    position: relative;

    &::before {
      position: absolute;
      content: '';
      width: 100%;
      height: 2px;
      top: 0;
      left: 0;
      background: #4DC591;
    }
  }

  .divide {
    position: relative;
    height: 100%;

    &::before {
      position: absolute;
      content: '';
      width: 100%;
      border-style: dashed;
      top: 0;
      margin: auto;
      bottom: 0;
      height: 1px;
      border-color: #EFEFEF;
      border-collapse: separate;
      border-width: 0.5px;
    }
  }
}
