:root {
  --primary: #1362DE;
  --secondary: #9BA1FF;
  --tertiary: #FF7648;
  --black: #191B32;
  --gray: #9295A3;
  --eb2f95: #eb2f95;
  --f5222d: #f5222d;
  --fbdb14: #fbdb14;
  --fa8b16: #fa8b16;
  --17c2c3: #17c2c3;
  --52c51a: #52c51a;
  --1678ff: #1678ff;
  --722ed1: #722ed1;
  --3055eb: #3055eb;
  --fa551b: #fa551b;
  --fbad14: #fbad14;
  --a1d911: #a1d911;
}
