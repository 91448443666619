@use 'variables';

.sidebarWrap {
  max-height: calc(100vh - 56px);
  min-height: calc(100vh - 56px);

  .sidebar {
    max-width: 262px;
    width: 100%;
    height: 100%;
    border-radius: 30px;
    padding: 20px 0;

    :global(.ant-menu-inline-collapsed) {
      :global(.ant-menu-item-icon) {
        min-width: 24px;
      }
    }

    .maskIcon {
      display: none;
      background-color: #1362DE
    }

    .menu {
      li {
        border-radius: 0;
        display: flex;
        align-items: center;
        margin: 0 0 16px 0;
        position: relative;


        &:before {
          position: absolute;
          content: '';
          width: 4px;
          height: 100%;
          left: 0;
          top: 0;
          background: var(--primary);
          display: none;
        }

        &:hover, &:global(.ant-menu-item-selected) {
          color: var(--primary);
          background: linear-gradient(90deg, rgba(46, 97, 214, 0.10) 0%, rgba(46, 97, 214, 0.03) 100%);

          img {
            display: none;
          }

          .maskIcon {
            display: block;
          }

          &:before {
            display: block;
          }
        }
      }
    }
  }
}
