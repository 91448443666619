@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@layer utilities {
  @variants responsive {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
  }
}

html, body {
  overflow-x: hidden;
  position: relative;
  width: 100%;
  height: 100%;
}

.ant-btn-primary {
  background-color: #2E61D6;
}

.bg-hint {
  background: url("assets/img/background-tiny.png") no-repeat fixed top left;
}

.main-layout {
  background-size: cover !important;
  min-height: 100%;
  max-height: 100vh;
  height: 100%;
  position: relative;
  background: url("assets/img/background-tiny.png") no-repeat fixed top left;
}

.login-layout {
  background: url("assets/img/background-login.png") no-repeat top left;
  background-size: 100% auto;
  height: 100%;
  width: 100%;
  background-size: contain;

}

.custom-gradient {
  background-image: linear-gradient(180deg, #2E61D6 0%, rgba(46, 97, 214, 0.25) 100%);
  opacity: 0.3;
  filter: drop-shadow(0px 10px 15px rgba(237, 230, 230, 0.10));
}

.loading-hint {
  display: flex;
  height: 100vh;
  margin: auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .loading {
    display: flex;
    flex-direction: row;
  }

  .loading__letter {
    font-size: 35px;
    margin-top: 30px;
    font-weight: normal;
    letter-spacing: 4px;
    color: #1262de;
    animation-name: bounce;
    animation-duration: 2s;
    animation-iteration-count: infinite;
  }

  .loading__letter:nth-child(2) {
    animation-delay: .1s;
  }

  .loading__letter:nth-child(3) {
    animation-delay: .2s;
  }

  .loading__letter:nth-child(4) {
    animation-delay: .3s;
  }

  .loading__letter:nth-child(5) {
    animation-delay: .4s;
  }

  .loading__letter:nth-child(6) {
    animation-delay: .5s;
  }

  .loading__letter:nth-child(7) {
    animation-delay: .6s;
  }

  .loading__letter:nth-child(8) {
    animation-delay: .8s;
  }

  .loading__letter:nth-child(9) {
    animation-delay: 1s;
  }

  .loading__letter:nth-child(10) {
    animation-delay: 1.2s;
  }

  @keyframes bounce {
    0% {
      transform: translateY(0px)
    }
    40% {
      transform: translateY(-40px);
    }
    80%,
    100% {
      transform: translateY(0px);
    }
  }
}
