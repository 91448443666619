.chatBot {
  box-shadow: 0 20px 50px 0 rgba(241, 224, 224, 0.30);
  background: white;
  overflow: hidden;

  .content {
    p {
      margin: 0.375rem 0;
    }
  }

  .input {
    &:global(.ant-input) {
      line-height: 1.8rem;
      border: none;
      height: 36px;
      min-height: 36px;
      padding-left: 16px;
      background: #F2F5FA;
      border-radius: 10px;
    }
  }


  .typing {
    align-items: center;
    display: flex;
    height: 17px;

    .dot {
      animation: mercuryTypingAnimation 1.8s infinite ease-in-out;
      background-color: #6cad96; //rgba(20,105,69,.7);
      border-radius: 50%;
      height: 7px;
      margin-right: 4px;
      vertical-align: middle;
      width: 7px;
      display: inline-block;
    }

    .dot:nth-child(1) {
      animation-delay: 200ms;
    }

    .dot:nth-child(2) {
      animation-delay: 300ms;
    }

    .dot:nth-child(3) {
      animation-delay: 400ms;
    }

    .dot:last-child {
      margin-right: 0;
    }
  }

  @keyframes mercuryTypingAnimation {
    0% {
      transform: translateY(0px);
      background-color: #6cad96; // rgba(20,105,69,.7);
    }
    28% {
      transform: translateY(-7px);
      background-color: #9ecab9; //rgba(20,105,69,.4);
    }
    44% {
      transform: translateY(0px);
      background-color: #b5d9cb; //rgba(20,105,69,.2);
    }
  }

}

