.checkbox {
  width: 20px;
  height: 20px;
  border-radius: 4px;
  display: inline-block;
  position: relative;
  cursor: pointer;

  &:hover {
    .mask {
      background: var(--color);
      opacity: 0.4;
    }
  }

  .mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    border-radius: 5px;
    border: 1px solid var(--color);

    &:after {
      content: "";
      position: absolute;
      display: none;
      top: 50%;
      left: 50%;
      width: 5px;
      height: 10px;
      border: solid var(--color);
      border-width: 0 3px 3px 0;
      transform: translate(-50%, -50%) rotate(45deg);
    }
  }

  input:checked ~ .mask {
    background: var(--color);
  }

  input:checked ~ .mask:after {
    display: block;
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
}
