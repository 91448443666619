.treeMenu {
  :global(.ant-tree-treenode), :global(.ant-tree-node-content-wrapper) {
    max-width: 100%;
    width: 100%;
  }

  &.switcherHidden {
    :global(.ant-tree-switcher) {
      display: none;
    }
  }
}
